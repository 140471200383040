import React, { useState, useEffect } from 'react';
import './orderandreturn.scss';

const Orderandreturn = () => {
  const [orders, setOrders] = useState([]);
  const [returns, setReturns] = useState([]);
  const [isOrderVisible, setIsOrderVisible] = useState(true);

  // Fetch orders from the API
  const fetchOrders = async () => {
    try {
      const response = await fetch('https://manageapi.jetsonweb.com/api/orders');
      const data = await response.json();
      setOrders(data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  // Fetch returns from the API
  const fetchReturns = async () => {
    try {
      const response = await fetch('https://manageapi.jetsonweb.com/api/returns');
      const data = await response.json();
      setReturns(data);
    } catch (error) {
      console.error("Error fetching returns:", error);
    }
  };

  // Fetch data when the component mounts
  useEffect(() => {
    fetchOrders();
    fetchReturns();
  }, []);

  // Handle order status update
  const updateOrderStatus = async (orderId, status) => {
    try {
      const response = await fetch(`https://manageapi.jetsonweb.com/api/orders/${orderId}/status`, {
        method: 'PUT',
        body: JSON.stringify({ status }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        // Update the order status in the local state
        setOrders(orders.map(order => 
          order.id === orderId ? { ...order, status } : order
        ));
      } else {
        console.error('Error updating order status');
      }
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  const toggleView = () => {
    setIsOrderVisible((prev) => !prev);
  };

  return (
    <div className="order-return-container">
      <h1 className='To'>Order and Return Management</h1>

      <div className="button-container">
        <button 
          className={`toggle-button ${isOrderVisible ? 'active' : ''}`} 
          onClick={toggleView}
        >
          Order
        </button>
        <button 
          className={`toggle-button ${!isOrderVisible ? 'active' : ''}`} 
          onClick={toggleView}
        >
          Return
        </button>
      </div>

      <div className="section-container">
        <div className={`section order-section ${isOrderVisible ? 'active' : ''}`}>
          <h2 className='To2'>Ordered Items</h2>
          <div className="items-grid">
            {orders.map((order) => (
              <div className="item-card" key={order.id}>
                <h3 className="item-name">Customer: {order.full_name}</h3>
                <p className="item-details">Email: {order.email}</p>
                <p className="item-details">Phone Number: {order.phone_number}</p>
                <p className="item-details">Company: {order.company_name}</p>
                <p className="item-details">Quantity: {order.quantity}</p>
                <p className="item-details">Order Details: {order.order_details}</p>
                <p className="item-details">Date: {order.created_at}</p>
                <p className="item-details">Status: {order.status}</p>
                <button
                  onClick={() => updateOrderStatus(order.id, order.status === 'Received' ? 'Sent' : 'Received')}
                  className={`status-button ${order.status === 'Received' ? 'received' : 'sent'}`}
                >
                  {order.status === 'Received' ? 'Sent' : 'Received'}
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className={`section return-section ${!isOrderVisible ? 'active' : ''}`}>
          <h2>Returned Items</h2>
          <div className="items-grid">
            {returns.map((returned) => (
              <div className="item-card" key={returned.id}>
                <h3 className="item-name">Customer: {returned.name}</h3>
                <p className="item-details">Company: {returned.company_name}</p>
                <p className="item-details">Email: {returned.email}</p>
                <p className="item-details">Phone Number: {returned.phone_number}</p>
                <p className="item-details">Comment: {returned.comment}</p>
                <p className="item-details">Date: {returned.created_at}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orderandreturn;
