import React, { useState } from 'react';
import axios from 'axios';
import './job.scss';

const Job = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [requirements, setRequirements] = useState('');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!title || !description || !requirements) {
      setMessage('All fields except file are required.');
      return;
    }

    // Create form data
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('requirements', requirements);
    if (file) {
      formData.append('file', file);
    }

    try {
      // Send form data to the server
      await axios.post('https://manageapi.jetsonweb.com/api/jobs', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setMessage('Job vacancy uploaded successfully!');
      setTitle('');
      setDescription('');
      setRequirements('');
      setFile(null);
    } catch (error) {
      console.error('Error uploading job vacancy:', error);
      setMessage('Error uploading job vacancy. Please try again.');
    }
  };

  return (
    <div className="job-container">
      <h1>Upload Job Vacancy</h1>
      <form onSubmit={handleSubmit} className="job-form">
        <label className="label">Job Title:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter job title"
          className="input"
        />

        <label className="label">Job Description:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter job description"
          className="textarea"
        />

        <label className="label">Requirements:</label>
        <textarea
          value={requirements}
          onChange={(e) => setRequirements(e.target.value)}
          placeholder="Enter job requirements"
          className="textarea"
        />

        <label className="label">Upload Job File (Optional):</label>
        <input type="file" onChange={handleFileChange} className="file-input" />

        <button type="submit" className="submit-button">Upload Job</button>

        {message && <div className="message">{message}</div>}
      </form>
    </div>
  );
};

export default Job;
