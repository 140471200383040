import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './analytics.scss';

const Analytics = () => {
  const [counts, setCounts] = useState({ approvedEmployees: 0, approvedPartners: 0, pendingPartners: 0 });
  const [view, setView] = useState('partners');
  const [partners, setPartners] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [error, setError] = useState(''); // To handle errors
  const [employeeDetails, setEmployeeDetails] = useState(null); // To store employee details


  const defaultImage = 'https://via.placeholder.com/150'; // Temporary image URL

  // Fetch counts for analytics
  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await axios.get('https://manageapi.jetsonweb.com/api/analytics/counts');
        setCounts(response.data);
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };
    fetchCounts();
  }, []);

  // Fetch lists based on the current view
  useEffect(() => {
    const fetchData = async () => {
      const endpoint = view === 'partners' ? 'https://manageapi.jetsonweb.com/api/partners' : 'https://manageapi.jetsonweb.com/api/employees';
      try {
        const response = await axios.get(endpoint);
        console.log('Fetched employees:', response.data); // Log response data
        view === 'partners' 
          ? setPartners(response.data) 
          : setEmployees(response.data.map(emp => ({
              ...emp,
              id: emp.id // Ensure id is set
            })));
      } catch (error) {
        console.error(`Error fetching ${view} data:`, error);
      }
    };
    fetchData();
  }, [view]);
  

  // Approve or decline user
  const updatePartnerStatus = async (fullName, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    const payload = { fullName, currentStatus };

    try {
      const endpoint = 'https://manageapi.jetsonweb.com/api/users/updateStatus';

      await axios.put(endpoint, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Update local state
      setPartners((prev) =>
        prev.map((partner) =>
          partner.full_name === fullName ? { ...partner, isApproved: newStatus } : partner
        )
      );
    } catch (error) {
      console.error('Error updating partner status:', error);
    }
  };

  const updateEmployeeStatus = async (fullName, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    const payload = { fullName, currentStatus };

    try {
      const endpoint = 'https://manageapi.jetsonweb.com/api/employees/updateStatus';

      await axios.put(endpoint, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Update local state
      setEmployees((prev) =>
        prev.map((employee) =>
          employee.full_name === fullName ? { ...employee, isApproved: newStatus } : employee
        )
      );
    } catch (error) {
      console.error('Error updating employee status:', error);
    }
  };

  // Fetch details for pop-ups
  const handlePartnerClick = async (id) => {
    try {
      const response = await axios.get(`https://manageapi.jetsonweb.com/api/partners/${id}`);
      setSelectedPartner(response.data);
    } catch (error) {
      console.error('Error fetching partner details:', error);
      setError('Could not fetch partner details');
    }
  };
  
  const handleEmployeeClick = async (id) => {
    console.log('Received Employee ID:', id); // Log the ID to check if it's correct
  
    if (!id) {
      console.error('Employee ID is missing!');
      setError('Employee ID is missing!');
      return;
    }
  
    try {
      const response = await axios.get(`https://manageapi.jetsonweb.com/api/employees/${id}`);
      console.log('Employee details:', response.data);
      setSelectedEmployee(response.data);
      setError(''); // Clear the error when data is fetched successfully
    } catch (error) {
      console.error('Error fetching employee details:', error);
      setError('Error fetching employee details!');
    }
  };
  
  
  
  

  // Close popups
  const closePartnerPopup = () => {
    setSelectedPartner(null);
  };

  const closeEmployeePopup = () => {
    setSelectedEmployee(null);
  };

  return (
    <div className="analytics-container">
      <h1>Analytics</h1>

      {/* Counts Section */}
      <div className="counts-row">
        <div className="count-box">
          <h3>Employees</h3>
          <p>{counts.approvedEmployees || 0}</p>
        </div>
        <div className="count-box">
          <h3>Partners</h3>
          <p>{counts.approvedPartners || 0}</p>
        </div>
        <div className="count-box">
          <h3>Pending Partners</h3>
          <p>{counts.pendingPartners || 0}</p>
        </div>
      </div>

      {/* Toggle View Buttons */}
      <div className="buttons-row">
        <button
          onClick={() => setView('partners')}
          className={view === 'partners' ? 'active' : ''}
        >
          Partners
        </button>
        <button
          onClick={() => setView('employees')}
          className={view === 'employees' ? 'active' : ''}
        >
          Employees
        </button>
      </div>

      {/* Lists Section */}
      <div className="list-container">
        {view === 'partners' && partners.length > 0 ? (
          partners.map((partner) => (
            <div key={partner.email} className="user-card">
              <img
                src={partner.image || defaultImage}
                alt={`${partner.full_name}'s profile`}
                className="user-image"
              />
              <div className="user-info">
                <h4 onClick={() => handlePartnerClick(partner.id)}>{partner.full_name}</h4>
                <div className="button-group">
                  <button
                    onClick={() => updatePartnerStatus(partner.full_name, partner.isApproved)}
                    className={partner.isApproved === 1 ? 'decline-button' : 'approve-button'}
                  >
                    {partner.isApproved === 1 ? 'Decline' : 'Approve'}
                  </button>
                  <button
                    onClick={() => handlePartnerClick(partner.id)}
                    className="view-profile-button"
                  >
                    View Profile
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : view === 'employees' && employees.length > 0 ? (
          employees.map((employee) => (
            <div key={employee.id} className="user-card">
              <img
                src={employee.profile_image || defaultImage}
                alt={`${employee.full_name}'s profile`}
                className="user-image"
              />
              <div className="user-info">
                <h4>{employee.full_name}</h4>
                <div className="button-group">
                  <button
                    onClick={() => updateEmployeeStatus(employee.full_name, employee.isApproved)}
                    className={employee.isApproved === 1 ? 'decline-button' : 'approve-button'}
                  >
                    {employee.isApproved === 1 ? 'Decline' : 'Approve'}
                  </button>
                  <button
                    onClick={() => {
                      console.log('Employee ID:', employee.id); // Add this for debugging
                      handleEmployeeClick(employee.id);
                    }}
                    className="view-profile-button"
                  >
                    View Profile
                  </button>
                </div>
              </div>
            </div>
          ))    
        ) : (
          <p>No data available</p>
        )}
      </div>

      {/* Partner Popup */}
      {selectedPartner && (
        <div className="popup">
          <div className="popup-content">
            <span className="close-button" onClick={closePartnerPopup}>&times;</span>
            <h3>{selectedPartner.full_name}</h3>
            <img src={selectedPartner.image || defaultImage} alt={`${selectedPartner.full_name}'s profile`} />
            <p>Company: {selectedPartner.company_name}</p>
            <p>Email: {selectedPartner.email}</p>
            <p>Username: {selectedPartner.username}</p>
          </div>
        </div>
      )}

      {/* Employee Popup */}
      {selectedEmployee && (
        <div className="popup">
          <div className="popup-content">
            <span className="close-button" onClick={closeEmployeePopup}>&times;</span>
            <h3>{selectedEmployee.full_name}</h3>
            <img src={selectedEmployee.image || defaultImage} alt={`${selectedEmployee.full_name}'s profile`} />
            <p>Email: {selectedEmployee.email}</p>
            <p>Username: {selectedEmployee.username}</p>
            <p>Role: {selectedEmployee.role}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Analytics;
