import React, { useState } from 'react';
import './login.scss';
import Logo from '../assets/photo_2_2024-08-17_15-14-53.jpg';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Make sure axios is imported

const Login = () => {
    const [email, setEmail] = useState(''); // State for email
    const [password, setPassword] = useState(''); // State for password
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(
                'https://manageapi.jetsonweb.com/api/admins/login', 
                { email, password }, 
                { withCredentials: true } // Ensure credentials are sent with the request
            );

            // Store the token in local storage if you are using JWT
            localStorage.setItem('token', response.data.token);
            navigate('/Sidebar'); // Redirect to Sidebar after successful login
        } catch (error) {
            console.error('Login error:', error.response ? error.response.data : error.message);
            alert('Invalid email or password'); // Provide feedback to the user
        }
    };
    
    const handleSignup = (event) => {
        event.preventDefault();
        navigate('/Signup');
    };

    return (
        <div className="login-container">
            <div className="top">
                <div className="wrapper">
                    <img className="logo" src={Logo} alt="Logo" />
                </div>
            </div>
            <div className="form-containerL">
                <h1 className="title">Login</h1>
                <form onSubmit={handleLogin}>
                    <input
                        type="text"
                        className="input-field"
                        placeholder="Email"
                        value={email} // Bind email state
                        onChange={(e) => setEmail(e.target.value)} // Update email state
                        required // Add required attribute
                    />
                    <div className="password-containerl">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            className="input-field"
                            placeholder="Password"
                            value={password} // Bind password state
                            onChange={(e) => setPassword(e.target.value)} // Update password state
                            required // Add required attribute
                        />
                        <button type="button" className="toggle-password" onClick={togglePasswordVisibility}>
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </button>
                    </div>
                    <button type="submit" className="login-button">Log In</button>
                    <p className="footer-text">
                        Don't have an account? <a onClick={handleSignup}>Sign up</a>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default Login;
