import React, { useState } from 'react';
import axios from 'axios';
import './news&blogs.scss'; // Ensure this path is correct

const NewsUpload = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [publishDate, setPublishDate] = useState('');
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [message, setMessage] = useState('');

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };

  const handleVideoChange = (event) => {
    const selectedVideo = event.target.files[0];
    setVideo(selectedVideo);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation
    if (!title || !description || !publishDate) {
      setMessage('Please fill in all required fields.');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('publish_date', publishDate); // Ensure this matches your API

    // Append files if they exist
    if (image) {
      formData.append('image', image);
    }

    if (video) {
      formData.append('video', video);
    }

    try {
      const response = await axios.post('https://manageapi.jetsonweb.com/api/news_blogs', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setMessage('News upload successful!');
      // Clear the form
      setTitle('');
      setDescription('');
      setPublishDate('');
      setImage(null);
      setVideo(null);
      console.log(response.data);
    } catch (error) {
      // Error handling
      if (error.response) {
        console.error('Error data:', error.response.data);
        setMessage('Error uploading news: ' + error.response.data.message);
      } else {
        console.error('Error:', error.message);
        setMessage('Error uploading news. Please try again.');
      }
    }
  };

  return (
    <div className="news-upload-container">
      <h1>Upload News & Blogs</h1>
      <form onSubmit={handleSubmit} className="news-upload-form">
        {image && (
          <div className="imagePreview">
            <img
              src={URL.createObjectURL(image)}
              alt="Preview"
              className="previewImage"
            />
          </div>
        )}
        <label className="label">image:</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="fileInput"
        />

        {video && (
          <div className="videoPreview">
            <video
              width="200"
              height="200"
              controls
              src={URL.createObjectURL(video)}
            />
          </div>
        )}
        <label className="label">video:</label>
        <input
          type="file"
          accept="video/*"
          onChange={handleVideoChange}
          className="fileInput"
        />

        <label className="label">Title:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter blog or news title"
          className="input"
          required
        />

        <label className="label">Description:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Write the content here"
          className="textarea"
          required
        />

        <label className="label">Publish Date:</label>
        <input
          type="date"
          value={publishDate}
          onChange={(e) => setPublishDate(e.target.value)}
          className="input"
          required
        />

        <button type="submit" className="submitButton">Upload</button>
        {message && <div className="message">{message}</div>}
      </form>
    </div>
  );
};

export default NewsUpload;
