import React, { useState } from 'react';
import axios from 'axios';
import './announcement.scss';

const AnnouncementUpload = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState('');

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleFileRemove = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter(file => file.name !== fileName));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Adjust validation: allow submission without files
    if (!title || !description || !date) {
      setMessage('Please fill in all required fields.');
      return;
    }
  
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('announcement_date', date);
  
    // Append files only if any files are selected
    if (files.length > 0) {
      files.forEach((file, index) => {
        formData.append('files', file);
      });
    }
  
    try {
      const response = await axios.post('https://manageapi.jetsonweb.com/api/announcements', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('Announcement uploaded successfully!');
      setTitle('');
      setDescription('');
      setDate('');
      setFiles([]);
      console.log(response.data);
    } catch (error) {
      console.error('Error uploading announcement:', error);
      setMessage('Error uploading announcement. Please try again.');
    }
  };
  

  return (
    <div className="container">
      <h1>Upload Announcement</h1>
      <form onSubmit={handleSubmit} className="form">
        <label className="label">Title:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter the title of the announcement"
          className="input"
        />

        <label className="label">Description:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Provide a brief description of the announcement"
          className="textarea"
        />

        <label className="label">Date:</label>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="input"
        />

        <label className="label">Upload Files:</label>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="fileInput"
        />
        <div className="file-list">
          {files.map((file, index) => (
            <div key={index} className="file-item">
              {file.name} <button onClick={() => handleFileRemove(file.name)}>Remove</button>
            </div>
          ))}
        </div>

        <button type="submit" className="submitButton">Upload</button>
        {message && <div className="message">{message}</div>}
      </form>
    </div>
  );
};

export default AnnouncementUpload;
