import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './adminProfile.scss';

const AdminProfile = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [message, setMessage] = useState('');

  // Fetch admin profile on component mount
  const fetchAdminProfile = async () => {
    try {
      const response = await axios.get('https://manageapi.jetsonweb.com/api/admins/profile', {
        withCredentials: true,
      });
      console.log('Admin Profile:', response.data);
      // Set state with fetched data
      setFullName(response.data.full_name);
      setEmail(response.data.email);
      setProfileImageUrl(response.data.profile_image ? `data:image/jpeg;base64,${response.data.profile_image.toString('base64')}` : ''); // Update profile image (assuming it's stored as binary in the DB)
    } catch (error) {
      console.error('Error fetching admin profile:', error.response?.data || error.message);
    }
  };

  // Fetch profile on component mount
  useEffect(() => {
    fetchAdminProfile();
  }, []);
  
  // Handle image upload
  const handleImageChange = (event) => {
    setProfileImage(event.target.files[0]);
    setProfileImageUrl(URL.createObjectURL(event.target.files[0]));
  };

  // Handle form submission for updating profile
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!fullName || !email) {
      setMessage('Full name and email are required.');
      return;
    }

    if (password && password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    const formData = new FormData();
    formData.append('full_name', fullName);
    formData.append('email', email);
    if (password) {
      formData.append('password', password);
    }
    if (profileImage) {
      formData.append('profile_image', profileImage);
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setMessage('No session ID found. Please log in.');
        return;
      }

      await axios.put('https://manageapi.jetsonweb.com/api/admins/update', formData, {
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
        withCredentials: true,
      });

      setMessage('Profile updated successfully!');
      setPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error updating profile:', error);
      setMessage('Error updating profile: ' + (error.response?.data?.message || 'Unknown error'));
    }
  };

  return (
    <div className="container">
      <h1>Admin Profile</h1>
      <form onSubmit={handleSubmit} className="form">
        <label className="label">Profile Image:</label>
        {profileImageUrl && (
          <div className="imagePreview">
            <img src={profileImageUrl} alt="Profile Preview" className="previewImage" />
          </div>
        )}
        <input type="file" onChange={handleImageChange} accept="image/*" className="fileInput" />

        <label className="label">Full Name:</label>
        <input
          type="text"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          placeholder="Enter your name"
          className="input"
        />

        <label className="label">Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="admin@example.com"
          className="input"
        />

        <label className="label">New Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter new password"
          className="input"
        />

        <label className="label">Confirm New Password:</label>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm new password"
          className="input"
        />

        <button type="submit" className="submitButton">Update Profile</button>

        {message && <div className="message">{message}</div>}
      </form>
    </div>
  );
};

export default AdminProfile;
