import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FaBars,
  FaChartLine,
  FaUserPlus,
  FaUpload,
  FaUser,
  FaMicrophone,
  FaNewspaper,
  FaShopify,
  FaNetworkWired,
  FaBriefcase,
} from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import './sidebar.scss';
import Analytics from '../side-manu/analytics/Analytics';
import Addemployee from '../side-manu/add employye/Addemployee';
import Upload from '../side-manu/upload content/Upload';
import AnnouncementUpload from '../side-manu/anouncment/Announcement';
import AdminProfile from '../side-manu/adminProfile/AdminProfile';
import NewsUpload from '../side-manu/news&blogs/news&blogs';
import Orderandreturn from '../side-manu/order&return/Orderandreturn';
import Job from '../side-manu/Jobopprtunities/Job';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeMenu, setActiveMenu] = useState('Analytics'); // Set default to 'Analytics'
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming user is logged in, you can replace this with actual login state
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      setActiveMenu('Analytics'); // Automatically show 'Analytics' when logged in
    }
  }, [isLoggedIn]);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const handleLogin = (event) => {
    event.preventDefault();
    // Here, you would handle the actual login logic, then navigate accordingly
    navigate("/Login");
  };

  const handleLogout = (event) => {
    event.preventDefault();
    // Here, you would clear the user's session/token or logout logic
    setIsLoggedIn(false); // Set login state to false (log out)
    navigate("/Login"); // Redirect to the login page
  };

  const renderContent = () => {
    switch (activeMenu) {
      case 'Analytics':
        return <Analytics />;
      case 'Orderandreturn':
        return <Orderandreturn />;
      case 'Add Employee':
        return <Addemployee />;
      case 'Upload Content':
        return <Upload />;
      case 'Job':
        return <Job />;
      case 'Requests':
        return <AnnouncementUpload />;
      case 'News & blogs':
        return <NewsUpload />;
      case 'Profile':
        return <AdminProfile />;
      default:
        return <Analytics />; // Default to Analytics if no menu is selected
    }
  };

  return (
    <div className="sidebar-layout">
      {/* Header */}
      <header className="header1">
        <FaBars className="hamburger-icon" onClick={handleCollapse} />
        <h1 className="header-title">Admin Panel</h1>
      </header>

      <div className="layout">
        {/* Sidebar */}
        <aside className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
          <ul className="sidebar-menu">
            <li onClick={() => handleMenuClick('Analytics')}>
              <FaChartLine />
              {!isCollapsed && <span>Analytics</span>}
            </li>
            <li onClick={() => handleMenuClick('Orderandreturn')}>
              <FaShopify />
              {!isCollapsed && <span>Order and return</span>}
            </li>
            <li onClick={() => handleMenuClick('Add Employee')}>
              <FaUserPlus />
              {!isCollapsed && <span>Add Employee</span>}
            </li>
            <li onClick={() => handleMenuClick('Upload Content')}>
              <FaUpload />
              {!isCollapsed && <span>Upload schedules</span>}
            </li>
            <li onClick={() => handleMenuClick('Job')}>
              <FaBriefcase />
              {!isCollapsed && <span>Job</span>}
            </li>
            <li onClick={() => handleMenuClick('Requests')}>
              <FaMicrophone />
              {!isCollapsed && <span>Anouncment</span>}
            </li>
            <li onClick={() => handleMenuClick('News & blogs')}>
              <FaNewspaper />
              {!isCollapsed && <span>News & blogs</span>}
            </li>
            <li onClick={() => handleMenuClick('Profile')}>
              <FaUser />
              {!isCollapsed && <span>Profile</span>}
            </li>
            <li onClick={handleLogout}> {/* Logout button */}
              <FiLogOut />
              {!isCollapsed && <span>Logout</span>}
            </li>
          </ul>
        </aside>

        {/* Main Content */}
        <main className="main-content">{renderContent()}</main>
      </div>
    </div>
  );
};

export default Sidebar;
