import React, { useState } from 'react';
import axios from 'axios';
import './upload.scss'; // Ensure this path is correct

const Upload = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [announcementDate, setAnnouncementDate] = useState(''); // Renamed state to announcementDate
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Form validation
    if (!title || !description || !announcementDate || !file) {
      setMessage('Please fill in all fields and upload a file.');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('announcement_date', announcementDate); // Changed to announcement_date
    formData.append('file', file);

    try {
      const response = await axios.post('https://manageapi.jetsonweb.com/api/uploadfile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('File uploaded successfully!');
      console.log(response.data); // Log the response from the server

      // Reset form
      setTitle('');
      setDescription('');
      setAnnouncementDate(''); // Reset announcement date
      setFile(null);
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Error uploading file. Please try again.');
    }
  };

  return (
    <div className="container">
      <h1>Upload schedules</h1>
      <form onSubmit={handleSubmit} className="form">
        <label className="label">Title:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter the title of the announcement"
          className="input"
          required
        />

        <label className="label">Description:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Provide a brief description of the announcement"
          className="textarea"
          required
        />

        <label className="label">Announcement Date:</label>
        <input
          type="date"
          value={announcementDate} // Use the updated state variable
          onChange={(e) => setAnnouncementDate(e.target.value)}
          className="input"
          required
        />

        <label className="label">Upload File:</label>
        <input
          type="file"
          accept=".pdf,.xls,.xlsx,.doc,.docx,.jpg,.jpeg,.png" // Acceptable file types
          onChange={handleFileChange}
          className="fileInput"
          required
        />

        <button type="submit" className="submitButton">Upload</button>

        {message && <div className="message">{message}</div>}
      </form>
    </div>
  );
};

export default Upload;
